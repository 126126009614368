@if (config.title) {
  <h2 class="confirmation-modal__title" mat-dialog-title>{{ config.title }}</h2>
}

<p class="confirmation-modal__content" mat-dialog-content>{{ config.content }}</p>

<mat-dialog-actions class="confirmation-modal__actions" align="center">
  @if (config.singleChoice !== true) {
    <button [mat-dialog-close]="false" mat-stroked-button color="primary" type="button" cdkFocusInitial>
      @if (config.denyIcon) {
        <mat-icon [svgIcon]="config.denyIcon" iconPositionStart />
      }
      {{ config.denyLabel || 'Annuler' }}
    </button>
  }
  <button [mat-dialog-close]="true" mat-flat-button color="primary" type="button">
    @if (config.acceptIcon) {
      <mat-icon [svgIcon]="config.acceptIcon" iconPositionStart />
    }
    {{ config.acceptLabel || 'Confirmer' }}
  </button>
</mat-dialog-actions>
