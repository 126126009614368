import { Injectable } from '@angular/core';

import type { HomeConfigurationResponse } from '@models/configuration/home-configuration';
import { withCache } from '@ngneat/cashew';
import { FbwWebservice } from '@webservices/fbw.webservice';

import { Observable, catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationWebservice extends FbwWebservice {
  constructor() {
    super('/configuration');
  }

  getHomeConfiguration(): Observable<HomeConfigurationResponse | null> {
    const url = `${this._baseUrl}/home`;
    const oneDayInMs = 86400000;
    return this._http
      .get<HomeConfigurationResponse>(url, { observe: 'body', context: withCache({ ttl: oneDayInMs }) })
      .pipe(catchError(() => of(null)));
  }
}
