import type { HomeConfiguration, HomeConfigurationResponse } from '@models/configuration/home-configuration';
import type { Recipe } from '@models/recipe/recipe';
import type { Review } from '@models/review/review';

import { duration } from 'dayjs';

export function parseHomeConfiguration(rawConfig: HomeConfigurationResponse): HomeConfiguration {
  const todayRecipe: Recipe = {
    ...rawConfig.todayRecipe,
    preparationTime: duration(rawConfig.todayRecipe.preparationTime),
    cookingTime: rawConfig.todayRecipe.cookingTime ? duration(rawConfig.todayRecipe.cookingTime) : undefined,
    standingTime: rawConfig.todayRecipe.standingTime ? duration(rawConfig.todayRecipe.standingTime) : undefined,
    createdAt: new Date(rawConfig.todayRecipe.createdAt),
    updatedAt: rawConfig.todayRecipe.updatedAt ? new Date(rawConfig.todayRecipe.updatedAt) : undefined,
  };
  const reviews: Review[] = rawConfig.reviews.map((rawReview) => ({
    ...rawReview,
    createTime:rawReview.createTime ?  new Date(rawReview.createTime) : undefined,
    updateTime: rawReview.updateTime ? new Date(rawReview.updateTime) : undefined,
    reviewReply: rawReview.reviewReply ? {
      ...rawReview.reviewReply,
      updateTime: rawReview?.reviewReply?.updateTime ?  new Date(rawReview.reviewReply.updateTime) : undefined,
    } : undefined,
  }));

  return {
    ...rawConfig,
    todayRecipe,
    reviews,
  };
}
