import { inject } from '@angular/core';
import type { ResolveFn } from '@angular/router';

import type { Recipe } from '@models/recipe/recipe';
import { ConfigurationService } from '@services/configuration/configuration.service';

import { map } from 'rxjs';

export const todaysRecipeResolver: ResolveFn<Recipe> = () =>
  inject(ConfigurationService).homeConfig$.pipe(map((config) => config.todayRecipe));
