export enum RecipeUnitType {
  SERVING = 'SERVING',
  PERSON = 'PERSON',
}

export enum RecipeIngredientUnit {
  NONE = 'NONE',
  GRAM = 'GRAM',
  CUP = 'CUP',
  TABLESPOON = 'TABLESPOON',
  TEASPOON = 'TEASPOON',
  OUNCE = 'OUNCE',
}

export enum RecipeStepType {
  DIRECTION = 'DIRECTION',
  ADVICE = 'ADVICE',
  WARNING = 'WARNING',
}
