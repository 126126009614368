import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';

import { HeaderComponent } from '@domains/organisms/header/header.component';

@Component({
  selector: 'fbw-core',
  template: `
    <main class="layout">
      <fbw-header class="header" />
      <div class="content">
        <router-outlet />
      </div>
    </main>
  `,
  styles: [
    `
      @use 'common/public-header' as header;

      .layout {
        min-height: calc(100vh - #{header.$height});
      }

      .header {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10;
      }

      .content {
        margin-top: header.$height;
      }
    `,
  ],
  standalone: true,
  imports: [RouterOutlet, HeaderComponent],
})
export class CoreComponent {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('/assets/sprite.svg'));
  }
}
