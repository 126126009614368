import { MatDialogConfig } from '@angular/material/dialog';
import { MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatTooltipDefaultOptions } from '@angular/material/tooltip';

export const matFormFieldDefaultOptions: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  floatLabel: 'always',
};

export const matTooltipDefaultOptions: MatTooltipDefaultOptions = {
  showDelay: 300,
  hideDelay: 0,
  touchendHideDelay: 0,
};

export const matDialogDefaultOptions: MatDialogConfig = {
  width: 'max(32.5rem, 40dvw)',
  maxWidth: '90dvw',
};
