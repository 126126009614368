import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';

import type { ContactData } from '@domains/templates/modals/contact-modal/contact-modal';
import { FbwWebservice } from '@webservices/fbw.webservice';

import { Observable, catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactWebservice extends FbwWebservice {
  constructor() {
    super('/contact');
  }

  sendContactRequest(contactData: ContactData): Observable<boolean> {
    const url = `${this._baseUrl}/send`;
    return this._http
      .post(url, contactData, {
        observe: 'response',
      })
      .pipe(
        map((response) => response.status === HttpStatusCode.NoContent),
        catchError(() => of(false))
      );
  }
}
