import { Routes } from '@angular/router';

import { todaysRecipeResolver } from '@commons/resolvers/recipe/todays-recipe.resolver';

export enum CoreRoute {
  Home = '',
  Legals = 'mentions-legales',
  TodaysRecipe = 'recette-du-moment',
}

export const CORE_ROUTES: Routes = [
  {
    path: CoreRoute.Home,
    loadComponent: async () => (await import('@features/home/home.component')).HomeComponent,
  },
  {
    title: 'Mentions légales',
    path: CoreRoute.Legals,
    loadComponent: async () => (await import('@features/legals/legals.component')).LegalsComponent,
  },
  {
    title: 'Recette du moment',
    path: CoreRoute.TodaysRecipe,
    loadComponent: async () =>
      (await import('@features/recipe-details/recipe-details.component')).RecipeDetailsComponent,
    resolve: {
      recipe: todaysRecipeResolver,
    },
  },
  {
    path: '**',
    redirectTo: CoreRoute.Home,
    pathMatch: 'full',
  },
];
