import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, ViewChild, inject } from '@angular/core';
import { MatButtonModule, MatIconButton } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { CoreRoute } from '@core/core.routes';
import { LogotypeComponent } from '@domains/molecules/logotype.component';
import { type Menu, MenuType } from '@domains/organisms/header/header';
import { ContactService } from '@services/contact/contact.service';
import { MENU_TYPE, MenuService } from '@services/menu/menu.service';

@Component({
  selector: 'fbw-header',
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    RouterLink,
    RouterLinkActive,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    LogotypeComponent,
  ],
  providers: [{ provide: MENU_TYPE, useValue: MenuType.PUBLIC }],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  readonly menuService = inject(MenuService);
  readonly #contactService = inject(ContactService);

  @ViewChild('menu') menuRef!: ElementRef<HTMLUListElement>;
  @ViewChild('menuButton') menuButtonRef!: MatIconButton;

  readonly CoreRoute = CoreRoute;

  readonly publicMenu: Menu = [
    {
      id: 'home',
      label: 'Accueil',
      isAction: false,
      path: `/${CoreRoute.Home}`,
    },
    {
      id: 'todays-recipe',
      label: 'Recette du moment',
      isAction: false,
      path: `/${CoreRoute.TodaysRecipe}`,
    },
    {
      id: 'contact',
      label: 'Contact',
      isAction: true,
      action: (): void => this.#contactService.openModal(),
    },
  ];

  @HostListener('document:click', ['$event.target'])
  handleClickOutsideMenu(target: HTMLElement): void {
    if (!target || this.menuButtonRef._elementRef.nativeElement.contains(target)) {
      return;
    }
    if (this.menuService.isMenuOpen && !this.menuRef.nativeElement.contains(target)) {
      this.menuService.closeMenu();
    }
  }
}
