import type { RecipeIngredient } from '@models/recipe/recipe';
import { RecipeIngredientUnit } from '@models/recipe/recipe.enum';

export const nestleDarkChocolateBar: RecipeIngredient = {
  id: '-1',
  singularName: 'tablette de chocolat noir corsé Nestlé',
  pluralName: 'tablettes de chocolat noir corsé Nestlé',
  unit: RecipeIngredientUnit.NONE,
};

export const caramelizedPistachioInPieces: RecipeIngredient = {
  id: '-2',
  singularName: 'pistache caramelisée en morceaux',
  pluralName: 'pistaches caramelisée en morceaux',
  unit: RecipeIngredientUnit.GRAM,
};

export const driedCranberrie: RecipeIngredient = {
  id: '-3',
  singularName: 'cranberrie séchée',
  pluralName: 'cranberries séchées',
  unit: RecipeIngredientUnit.GRAM,
};

export const hazelnut: RecipeIngredient = {
  id: '-4',
  singularName: 'noisette',
  pluralName: 'noisettes',
  unit: RecipeIngredientUnit.NONE,
};

export const almond: RecipeIngredient = {
  id: '-5',
  singularName: 'amande',
  pluralName: 'amandes',
  unit: RecipeIngredientUnit.NONE,
};

export const botteDeRadis: RecipeIngredient = {
  id: '-6',
  singularName: 'botte de radis',
  pluralName: 'bottes de radis',
  unit: RecipeIngredientUnit.NONE,
};

export const cucumber: RecipeIngredient = {
  id: '-7',
  singularName: 'concombre',
  pluralName: 'concombres',
  unit: RecipeIngredientUnit.NONE,
};

export const carotteRapee: RecipeIngredient = {
  id: '-8',
  singularName: 'carottes râpée',
  pluralName: 'carottes râpées',
  unit: RecipeIngredientUnit.GRAM,
};
export const poisChiche: RecipeIngredient = {
  id: '-9',
  singularName: 'pois chiche',
  pluralName: 'pois chiches',
  unit: RecipeIngredientUnit.GRAM,
};

export const moutarde: RecipeIngredient = {
  id: '-10',
  singularName: 'moutarde',
  pluralName: 'moutarde',
  unit: RecipeIngredientUnit.TEASPOON,
};

export const huileOlive: RecipeIngredient = {
  id: '-11',
  singularName: "huile d'olive",
  pluralName: "huile d'olive",
  unit: RecipeIngredientUnit.TEASPOON,
};

export const parmesan: RecipeIngredient = {
  id: '-12',
  singularName: 'parmesan',
  pluralName: 'parmesan',
  unit: RecipeIngredientUnit.GRAM,
};

export const thon: RecipeIngredient = {
  id: '-13',
  singularName: 'thon',
  pluralName: 'thon',
  unit: RecipeIngredientUnit.GRAM,
};
