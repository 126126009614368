import type { InMemoryScrollingOptions, RouterConfigOptions } from '@angular/router';

export const routerConfigOptions: RouterConfigOptions = {
  onSameUrlNavigation: 'reload',
  paramsInheritanceStrategy: 'always',
};

export const inMemoryScrollingOptions: InMemoryScrollingOptions = {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'top',
};
