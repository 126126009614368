import type { RootRoute } from '@commons/utils/misc/router/types';

interface MenuBaseItem {
  id: string;
  label: string;
  icon?: string;
  isAction: boolean;
}

export type MenuAction = () => void;

export interface MenuNavItem extends MenuBaseItem {
  isAction: false;
  path: NonNullable<RootRoute>;
}

export interface MenuActionItem extends MenuBaseItem {
  isAction: true;
  action: MenuAction;
}

export type MenuItem = MenuNavItem | MenuActionItem;

export type Menu = MenuItem[];

export enum MenuType {
  PUBLIC,
  ADMIN,
}
