import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { EMAIL_REGEX, PHONE_SIMPLE_REGEX } from '@commons/utils/misc/form/constants';
import type { ContactData, ContactModalControls } from '@domains/templates/modals/contact-modal/contact-modal';
import { ContactService } from '@services/contact/contact.service';

import { NgxTrimDirectiveModule } from 'ngx-trim-directive';

@Component({
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    NgxTrimDirectiveModule,
  ],
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactModalComponent {
  readonly #contactService = inject(ContactService);
  readonly #formBuilder = inject(FormBuilder);

  readonly form: FormGroup<ContactModalControls> = this.#formBuilder.nonNullable.group({
    firstname: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    lastname: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
    email: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required, Validators.pattern(EMAIL_REGEX)],
    }),
    phone: new FormControl<string | null>(null, {
      validators: Validators.pattern(PHONE_SIMPLE_REGEX),
    }),
    content: new FormControl<string>('', { nonNullable: true, validators: Validators.required }),
  });

  sendContactRequest(): void {
    if (this.form.valid) {
      this.#contactService.closeModal(this.form.value as ContactData);
    }
  }
}
