import type { RecipeTool } from '@models/recipe/recipe';

export const bainMarie: RecipeTool = {
  id: '-1',
  singularName: 'bain-marie',
  pluralName: 'bains-marie',
  archived: false,
};

export const blender: RecipeTool = {
  id: '-2',
  singularName: 'mixeur',
  pluralName: 'mixeurs',
  archived: false,
};

export const bakingPlate: RecipeTool = {
  id: '-3',
  singularName: 'plaque',
  pluralName: 'plaques',
  archived: true,
};

export const tableSpoon: RecipeTool = {
  id: '-4',
  singularName: 'cuillière à soupe',
  pluralName: 'cuillières à soupe',
  archived: true,
};


export const mandoline: RecipeTool = {
  id: '-5',
  singularName: 'mandoline',
  pluralName: 'mandolines',
  archived: true,
};

export const passoire: RecipeTool = {
  id: '-6',
  singularName: 'passoire',
  pluralName: 'passoires',
  archived: true,
}

export const econome: RecipeTool = {
  id: '-7',
  singularName: 'économe',
  pluralName: 'économes',
  archived: true,
}
