<h2 class="contact-modal__title" mat-dialog-title>Contactez-moi</h2>
<form class="contact-modal__form" [formGroup]="form" mat-dialog-content>
  <mat-form-field class="contact-modal__form__field">
    <mat-label>Prénom</mat-label>
    <input [formControl]="form.controls.firstname" type="text" matInput required placeholder="Florence" trim />
    @if (form.controls.firstname.errors?.['required']) {
      <mat-error>Veuillez renseigner votre prénom.</mat-error>
    }
  </mat-form-field>

  <mat-form-field class="contact-modal__form__field">
    <mat-label>Nom</mat-label>
    <input [formControl]="form.controls.lastname" type="text" matInput required placeholder="Blanc" trim />
    @if (form.controls.lastname.errors?.['required']) {
      <mat-error>Veuillez renseigner votre nom.</mat-error>
    }
  </mat-form-field>

  <mat-form-field class="contact-modal__form__field">
    <mat-label>Adresse email</mat-label>
    <input
      [formControl]="form.controls.email"
      type="email"
      matInput
      required
      placeholder="florenceblanc@exemple.com"
      trim
    />
    @if (form.controls.email.errors) {
      <mat-error>Veuillez renseigner un email valide.</mat-error>
    }
  </mat-form-field>

  <mat-form-field class="contact-modal__form__field">
    <mat-label>Téléphone</mat-label>
    <input [formControl]="form.controls.phone" type="tel" matInput placeholder="06XXXXXXXX" trim />
    @if (form.controls.phone.errors) {
      <mat-error>Veuillez entrer un numéro de téléphone valide.</mat-error>
    }
  </mat-form-field>

  <mat-form-field class="contact-modal__form__field">
    <mat-label>Message</mat-label>
    <textarea
      [formControl]="form.controls.content"
      matInput
      required
      cdkTextareaAutosize
      placeholder="Posez vos questions ou écrivez ici vos demandes."
      trim="blur"
    ></textarea>
    @if (form.controls.content.errors?.['required']) {
      <mat-error>Vous ne pouvez pas envoyer un message vide.</mat-error>
    }
  </mat-form-field>
</form>

<mat-dialog-actions class="contact-modal__actions" align="center">
  <button mat-dialog-close mat-stroked-button color="primary" type="button" cdkFocusInitial>
    <mat-icon [svgIcon]="'close'" iconPositionStart /> Annuler
  </button>
  <button [disabled]="form.invalid" (click)="sendContactRequest()" mat-flat-button color="primary" type="button">
    <mat-icon [svgIcon]="'send'" iconPositionStart /> Envoyer
  </button>
</mat-dialog-actions>
