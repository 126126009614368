import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';

import { environment } from '@environment';

export abstract class FbwWebservice {
  protected readonly _http = inject(HttpClient);

  protected readonly _baseUrl: `/${string}` = environment.apiUrl;

  constructor(apiPath: `/${string}`) {
    this._baseUrl = `${environment.apiUrl}${apiPath}`;
  }
}
