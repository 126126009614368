/* eslint-disable no-console */
import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import type { ConfirmationModalConfig } from '@domains/templates/modals/confirmation-modal/confirmation-modal';
import { ConfirmationModalComponent } from '@domains/templates/modals/confirmation-modal/confirmation-modal.component';
import type { ContactData } from '@domains/templates/modals/contact-modal/contact-modal';
import { ContactModalComponent } from '@domains/templates/modals/contact-modal/contact-modal.component';
import { ContactWebservice } from '@webservices/contact/contact.webservice';

import { filter, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  readonly #dialog = inject(MatDialog);
  readonly #contactWebservice = inject(ContactWebservice);

  #modalRef: MatDialogRef<ContactModalComponent, ContactData> | null = null;

  openModal(): void {
    this.#modalRef = this.#dialog.open<ContactModalComponent, ContactData>(ContactModalComponent);
    this.#modalRef
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap((modalData) => this.#contactWebservice.sendContactRequest(modalData))
      )
      .subscribe({
        next: (hasMessageBeenSent) => this.#openMessageFeedbackModal(hasMessageBeenSent),
      });
  }

  closeModal(data?: ContactData | undefined): void {
    this.#modalRef?.close(data);
  }

  #openMessageFeedbackModal(hasMessageBeenSent: boolean): void {
    let title: string, content: string;
    if (hasMessageBeenSent) {
      title = 'Merci !';
      content = 'Votre message a bien été envoyé.';
    } else {
      title = 'Mince...';
      content = 'Une erreur est survenue.\nVeuillez réessayer plus tard.';
    }

    this.#dialog.open<ConfirmationModalComponent, ConfirmationModalConfig, boolean>(ConfirmationModalComponent, {
      data: {
        title,
        content,
        acceptLabel: 'OK',
        singleChoice: true,
      },
    });
  }
}
