import { Injectable, inject } from '@angular/core';

import { parseHomeConfiguration } from '@commons/utils/business/configuration/functions';
import { homeConfigurationResponseFixture } from '@fixtures/configuration/home';
import { ConfigurationWebservice } from '@webservices/configuration/configuration.webservice';

import { map, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  readonly homeConfig$ = inject(ConfigurationWebservice)
    .getHomeConfiguration()
    .pipe(
      map((rawConfig) => parseHomeConfiguration(rawConfig ?? homeConfigurationResponseFixture)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
}
