import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { InjectionToken } from '@angular/core';

import { type MenuAction, MenuType } from '@domains/organisms/header/header';

export const MENU_TYPE = new InjectionToken<MenuType>('menuType');

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private readonly document = inject(DOCUMENT);
  private readonly menuType = inject(MENU_TYPE);

  private static readonly menuOpeningStateMap: Map<MenuType, boolean> = new Map();

  private set isMenuOpen(isMenuOpen: boolean) {
    MenuService.menuOpeningStateMap.set(this.menuType, isMenuOpen);
  }

  get isMenuOpen(): boolean {
    return MenuService.menuOpeningStateMap.get(this.menuType)!;
  }

  constructor() {
    MenuService.menuOpeningStateMap.set(this.menuType, false);
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
    this.document.body.style.overflow = this.isMenuOpen ? 'hidden' : '';
  }

  closeMenu(): void {
    this.isMenuOpen = false;
    this.document.body.style.overflow = '';
  }

  handleMenuAction(menuAction: MenuAction): void {
    menuAction();
    this.closeMenu();
  }
}
