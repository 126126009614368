<header class="header">
  <button class="header__menu-button" (click)="menuService.toggleMenu()" #menuButton mat-icon-button type="button">
    <mat-icon [svgIcon]="menuService.isMenuOpen ? 'menu-close' : 'menu-burger'" />
  </button>
  <a class="header__logo" [routerLink]="['/', CoreRoute.Home]" (click)="menuService.closeMenu()">
    <img class="header__logo__emblem" src="assets/images/logo.png" alt="Logo de Florence Blanc" />
    <fbw-logotype class="header__logo__logotype" />
  </a>
  <nav class="header__menu" [ngClass]="{ 'header__menu--open': menuService.isMenuOpen }" role="navigation">
    <ul class="menu" #menu>
      @for (menuItem of publicMenu; track menuItem.id) {
        <li class="menu__item" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="menu__item--active">
          @if (!menuItem.isAction) {
            <a class="menu__item__link" [routerLink]="menuItem.path" (click)="menuService.closeMenu()">{{
              menuItem.label
            }}</a>
          } @else {
            <button
              class="menu__item__link"
              (click)="menuItem.isAction && menuService.handleMenuAction(menuItem.action)"
              type="button"
            >
              {{ menuItem.label }}
            </button>
          }
        </li>
      }
    </ul>
  </nav>
</header>
