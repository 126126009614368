import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { LOCALE_ID } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  TitleStrategy,
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';

import { FbwTitleStrategy } from '@commons/utils/misc/fbw-title-strategy';
import {
  matDialogDefaultOptions,
  matFormFieldDefaultOptions,
  matTooltipDefaultOptions,
} from '@config/angular-material.config';
import { inMemoryScrollingOptions, routerConfigOptions } from '@config/router.config';
import { CoreComponent } from '@core/core.component';
import { CORE_ROUTES } from '@core/core.routes';
import { provideHttpCache, withHttpCacheInterceptor } from '@ngneat/cashew';
import { MENU_TYPE } from '@services/menu/menu.service';

import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { QuicklinkStrategy, quicklinkProviders } from 'ngx-quicklink';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.locale('fr');

bootstrapApplication(CoreComponent, {
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR' },

    // Routing
    provideRouter(
      CORE_ROUTES,
      withRouterConfig(routerConfigOptions),
      withInMemoryScrolling(inMemoryScrollingOptions),
      withPreloading(QuicklinkStrategy),
      withComponentInputBinding(),
      withViewTransitions()
    ),
    quicklinkProviders,

    // HTTP
    provideHttpClient(withInterceptors([withHttpCacheInterceptor()])),
    provideHttpCache(),

    // UI elements
    provideAnimations(),
    { provide: TitleStrategy, useClass: FbwTitleStrategy },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: matFormFieldDefaultOptions },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: matTooltipDefaultOptions },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: matDialogDefaultOptions },
    { provide: MENU_TYPE, useValue: undefined },
  ],
})
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
