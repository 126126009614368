import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'fbw-logotype',
  standalone: true,
  template: `
    <div class="logotype">
      <span class="logotype__title">Florence Blanc</span>
      <span class="logotype__subtitle">Diététicienne Nutritionniste</span>
    </div>
  `,
  styles: [
    `
      @use 'variables' as var;

      .logotype {
        display: flex;
        flex-direction: column;
        width: fit-content;
        color: currentColor;

        &__title,
        &__subtitle {
          line-height: 1.25;
        }

        &__title {
          font-family: var.$font-title;
          font-size: 1.5rem;
          font-weight: 700;
          letter-spacing: 0.0975rem;
        }

        &__subtitle {
          letter-spacing: -0.025rem;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogotypeComponent {}
