import type { IsDto } from '@commons/utils/business/configuration/types';
import type { GetInTouchConfiguration } from '@domains/templates/get-in-touch/get-in-touch';
import {
  botteDeRadis,
  carotteRapee,
  cucumber,
  huileOlive,
  moutarde,
  parmesan,
  poisChiche,
  thon,
} from '@fixtures/recipe/ingredients';
import { econome, mandoline, passoire } from '@fixtures/recipe/tools';
import type {
  HomeConfigurationResponse,
  HomeHeroConfiguration,
  HomeWhoAmIConfiguration,
} from '@models/configuration/home-configuration';
import { RecipeResponse } from '@models/recipe/recipe';
import { RecipeStepType, RecipeUnitType } from '@models/recipe/recipe.enum';
import type { Review } from '@models/review/review';
import { ReviewRating } from '@models/review/review.enum';

const heroFixture: HomeHeroConfiguration = {
  images: [
    {
      name: "Photo d'une gaufre aux fruits",
      path: '/assets/static/home-hero-1.jpg',
    },
    {
      name: "Photo d'une salade de poix chiches",
      path: '/assets/static/home-hero-2.jpg',
    },
  ],
};

//FIXME a lier avec le backend.
/*const todaysRecipeFixture: RecipeResponse = {
  id: '-1',
  title: 'Mendiants maison',
  description: 'Un petit plat tout en gourmandise et équilibré pour votre goûter et toute votre famille.',
  image: {
    name: 'Photo des mendiants maison',
    path: '/assets/static/home-todays-recipe.jpg',
  },
  defaultQuantity: 20,
  unitType: RecipeUnitType.SERVING,
  preparationTime: 'PT10M',
  standingTime: 'PT2H',
  visible: true,
  createdAt: '2023-04-28T09:41:38.843Z',
  updatedAt: new Date().toISOString(),
  tools: [
    { ...bainMarie, quantity: 1 },
    { ...blender, quantity: 1 },
    { ...bakingPlate, quantity: 1 },
    { ...tableSpoon, quantity: 1 },
  ],
  ingredients: [
    { ...nestleDarkChocolateBar, quantity: 1 },
    { ...caramelizedPistachioInPieces, quantity: 10 },
    { ...driedCranberrie, quantity: 30 },
    { ...hazelnut, quantity: 20 },
    { ...almond, quantity: 20 },
  ],
  steps: [
    {
      id: '-1',
      order: 1,
      type: RecipeStepType.DIRECTION,
      content: 'Faire fondre le chocolat',
      subContent: "Mettre le chocolat au bain-marie jusqu'à ce que celui-ci soit fondu.",
    },
    {
      id: '-2',
      order: 2,
      type: RecipeStepType.DIRECTION,
      content: 'Mixer grossièrement les noisettes',
      subContent: 'Cette étape peut être réalisée durant la fonte du chocolat.',
    },
    {
      id: '-3',
      order: 3,
      type: RecipeStepType.DIRECTION,
      content: 'Mixer grossièrement les cranberries',
    },
    {
      id: '-4',
      order: 4,
      type: RecipeStepType.DIRECTION,
      content: 'Prendre une plaque et disposer une feuille de papier cuisson',
    },
    {
      id: '-5',
      order: 5,
      type: RecipeStepType.DIRECTION,
      content: 'Faire des ronds de chocolat',
      subContent: 'Prendre une cuillère et étaler sur la plaque le chocolat fondu en forme de ronds.',
    },
    {
      id: '-6',
      order: 6,
      type: RecipeStepType.DIRECTION,
      content: 'Saupoudrer chaque rond de chocolat avec un peu de pistache',
    },
    {
      id: '-7',
      order: 7,
      type: RecipeStepType.DIRECTION,
      content: 'Ajouter les noisettes mixées et les cranberries',
    },
    {
      id: '-8',
      order: 8,
      type: RecipeStepType.DIRECTION,
      content: 'Mettre une amande sur chaque rond de chocolat',
    },
    {
      id: '-9',
      order: 9,
      type: RecipeStepType.DIRECTION,
      content: 'Mettre au frigo la plaque avec les ronds de chocolat',
    },
    {
      id: '-10',
      order: 10,
      type: RecipeStepType.DIRECTION,
      content: 'Laisser reposer au frigo pendant au moins 2 heures',
    },
  ],
};*/

const secondRecipeFixture: RecipeResponse = {
  id: '-2',
  title: 'Salade composée',
  description: '',
  image: {
    name: 'Photo des mendiants maison',
    path: '/assets/static/home-secondary-todays-recipes.jpg',
  },
  defaultQuantity: 4,
  unitType: RecipeUnitType.PERSON,
  preparationTime: 'PT30M',
  visible: true,
  createdAt: '2023-04-28T09:41:38.843Z',
  updatedAt: new Date().toISOString(),
  tools: [
    { ...mandoline, quantity: 1 },
    { ...passoire, quantity: 1 },
    { ...econome, quantity: 1 },
  ],
  ingredients: [
    { ...botteDeRadis, quantity: 0.5 },
    { ...cucumber, quantity: 1 },
    { ...carotteRapee, quantity: 100 },
    { ...poisChiche, quantity: 300 },
    { ...thon, quantity: 140 },
    { ...moutarde, quantity: 2 },
    { ...huileOlive, quantity: 3 },
    { ...parmesan, quantity: 60 },
  ],
  steps: [
    {
      id: '-1',
      order: 1,
      type: RecipeStepType.DIRECTION,
      content: 'Séparer les radis de la botte',
      subContent: '',
    },
    {
      id: '-2',
      order: 2,
      type: RecipeStepType.DIRECTION,
      content: 'Laver les radis, le concombre et les carottes',
    },
    {
      id: '-3',
      order: 3,
      type: RecipeStepType.DIRECTION,
      content: 'Couper le bout des radis puis les couper en rondelle',
    },
    {
      id: '-4',
      order: 4,
      type: RecipeStepType.DIRECTION,
      content: 'Éplucher le concombre et les carottes',
    },
    {
      id: '-5',
      order: 5,
      type: RecipeStepType.DIRECTION,
      content: 'Couper le concombre en petit morceaux',
    },
    {
      id: '-6',
      order: 6,
      type: RecipeStepType.DIRECTION,
      content: "À l'aide d'une mandoline râper les carottes",
    },
    {
      id: '-7',
      order: 7,
      type: RecipeStepType.DIRECTION,
      content: 'Égoutter les pois chiches',
    },
    {
      id: '-8',
      order: 8,
      type: RecipeStepType.DIRECTION,
      content: 'Bien rincer les pois chiches',
    },
    {
      id: '-9',
      order: 9,
      type: RecipeStepType.DIRECTION,
      content: 'Mettre les légumes et les pois chiche dans un saladier',
    },
    {
      id: '-10',
      order: 10,
      type: RecipeStepType.DIRECTION,
      content: 'Égoutter les boites de thon',
    },
    {
      id: '-11',
      order: 11,
      type: RecipeStepType.DIRECTION,
      content: 'Émietter le thon puis le rajouter dans le saladier',
    },
    {
      id: '-12',
      order: 12,
      type: RecipeStepType.DIRECTION,
      content: 'Réaliser la sauce au parmesan :',
      subContent:
        "\ta. Mélanger la moutarde avec l'huile d’olive\n\tb. Ajouter le parmesan\n\tc. Bien mélanger le tout jusqu'à obtenir une sauce onctueuse",
    },
    {
      id: '-13',
      order: 13,
      type: RecipeStepType.DIRECTION,
      content: 'Rajouter la sauce à la salade composée',
    },
    {
      id: '-14',
      order: 14,
      type: RecipeStepType.DIRECTION,
      content: 'Mélanger le tout',
    },
  ],
};

const whoAmIFixture: HomeWhoAmIConfiguration = {
  content: `
    <p>Titulaire du BTS Diététique depuis <strong>3&nbsp;ans</strong>, j'ai commencé mes études par une <strong>licence de biologie</strong> me conférant une base de connaissance solide et étendue de la <strong>physiologie humaine</strong>.</p>
    <p>Souhaitant apporter soin et écoute, je me suis naturellement tournée vers la nutrition.</p>
    <p>Passionnée et attentive, je serais ravie de vous accompagner dans votre démarche en vous prodiguant les conseils et en mettant en place le suivi de qualité vous permettant d'atteindre vos objectifs.</p>
    <p>Toujours désireuse d'apprendre, je réalise régulièrement des formations afin de répondre au mieux à vos besoins.</p>
  `,
  image: {
    name: 'Portrait de Florence',
    path: '/assets/static/home-who-am-i.jpg',
  },
};

const reviewsFixture: Review<IsDto>[] = [
  {
    reviewId: 'abcdef',
    reviewer: {
      displayName: 'Coralie Bidet',
      profilePhotoUrl: 'https://lh3.googleusercontent.com/a-/ALV-UjXnyZmx1U4CA8sJeoqWdRZAZyzi1HbyIjtJutAnjrLcCY4',
      isAnonymous: false,
    },
    comment:
      "Florence Blanc est une diététicienne à l'écoute et bienveillante. Elle m'a permis de rééquilibrer mon alimentation durablement. Elle propose des consultations en visio, ce qui est vraiment pratique. Je recommande vivement les services de Florence Blanc à tous ceux qui recherchent une diététicienne compétente et attentionnée.",
    reviewReply: {} as Review<IsDto>['reviewReply'],
    name: '',
    createTime: '2023-08-10T19:57:30.000Z',
    updateTime: '2023-08-10T19:57:30.000Z',
    starRating: ReviewRating.FIVE,
  },
  {
    reviewId: 'ghijkl',
    reviewer: {
      displayName: 'Aurélien B.',
      profilePhotoUrl: '',
      isAnonymous: false,
    },
    comment:
      "Extrêmement professionnelle, empathique et sympathique, le suivi a été efficace (-7kg) sans contrainte. A l'écoute et pleine de bons conseils, Florence m'a donné des fiches de recettes afin de m'aider à atteindre mon objectif de poids.",
    reviewReply: {} as Review<IsDto>['reviewReply'],
    name: '',
    createTime: '2023-04-01T11:02:44.000Z',
    updateTime: '2023-04-01T11:02:44.000Z',
    starRating: ReviewRating.FIVE,
  },
  {
    reviewId: 'lmnopq',
    reviewer: {
      displayName: 'Fabienne',
      profilePhotoUrl: '',
      isAnonymous: false,
    },
    comment:
      'Florence est compétente, patiente, douce et de très bon conseil. C\'est le premier "régime" avec lequel je ne ressens pas de frustration. Efficace dans la durée.',
    reviewReply: {} as Review<IsDto>['reviewReply'],
    name: '',
    createTime: new Date().toISOString(),
    updateTime: new Date().toISOString(),
    starRating: ReviewRating.FIVE,
  },
  {
    reviewId: 'jJc9Ad',
    reviewer: {
      displayName: 'Sophie Sophie',
      profilePhotoUrl:
        'https://lh3.googleusercontent.com/a-/ALV-UjWqQkecNrpEPE_MdvK7SlOnuQ4mItPQzfZIEruJ9vP51rYuJus=w36-h36-p-rp-mo-br100',
      isAnonymous: false,
    },
    comment:
      "Je vous recommande cette diététicienne, le suivi en visio est agréable et pratique.\n Ses conseils m'ont permis de perdre 3kg sans effort surhumain et en gardant le plaisir de manger.",
    reviewReply: {} as Review<IsDto>['reviewReply'],
    name: '',
    createTime: new Date().toISOString(),
    updateTime: new Date().toISOString(),
    starRating: ReviewRating.FIVE,
  },
  {
    reviewId: 'OA1nbd',
    reviewer: {
      displayName: 'Nathalie Mechregui',
      profilePhotoUrl: '',
      isAnonymous: false,
    },
    comment:
      "Je recommande Florence pour son professionnalisme, son écoute et sa bienveillance. Ses rdv tous les 15 j m'apportent un vrai moment de pause , de sérénité et de partage. Et les résultats sont là :)",
    reviewReply: {} as Review<IsDto>['reviewReply'],
    name: '',
    createTime: new Date().toISOString(),
    updateTime: new Date().toISOString(),
    starRating: ReviewRating.FIVE,
  },
  {
    reviewId: 'p7f0Nd',
    reviewer: {
      displayName: 'Emilie Courvoisier',
      profilePhotoUrl: '',
      isAnonymous: false,
    },
    comment:
      "Je recommande vivement Florence pour son professionnalisme et sa gentillesse\n Elle est a l'écoute et comprends parfaitement nos impératifs\nPlan alimentaire adapté et sans contrainte\nMerci pour tous ces bons conseils et son partages de recettes",
    reviewReply: {} as Review<IsDto>['reviewReply'],
    name: '',
    createTime: new Date().toISOString(),
    updateTime: new Date().toISOString(),
    starRating: ReviewRating.FIVE,
  },
  {
    reviewId: 'ANWksb',
    reviewer: {
      displayName: 'Pierre-Victor Andouard',
      profilePhotoUrl: '',
      isAnonymous: false,
    },
    comment:
      'Super suivi avec atteinte de mes objectifs sans prise de tête et sans complexe !\nJe recommande plus que vivement pour sa gentillesse et son professionnalisme',
    reviewReply: {} as Review<IsDto>['reviewReply'],
    name: '',
    createTime: new Date().toISOString(),
    updateTime: new Date().toISOString(),
    starRating: ReviewRating.FIVE,
  },
];

const getInTouchFixture: GetInTouchConfiguration = {
  title: 'Vous souhaitez en savoir plus ?',
  subtitle: "N'hésitez pas à me contacter !",
  cta: 'Je prends contact',
};

export const homeConfigurationResponseFixture: HomeConfigurationResponse = {
  hero: heroFixture,
  todayRecipe: secondRecipeFixture,
  whoAmI: whoAmIFixture,
  reviews: reviewsFixture,
  getInTouch: getInTouchFixture,
};
